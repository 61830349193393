'use strict';

Gri.module({
  name: 'menu-alpha',
  ieVersion: null,
  $el: $('.menu-alpha'),
  container: '.menu-alpha',
  fn: function () {
    tofas.menu = {
      el: $('.menu-alpha'),
      itemTag: $('[data-sub]'),
      backTag: $('[data-back]'),
      init: function(){
        tofas.menu.sub();
        tofas.menu.back();
        tofas.menu.mobileMenuClose();
        if (window.matchMedia('(max-width: 991px)').matches) {
          tofas.menu.positionReset();
		  tofas.menu.infoboxReset();
        }else{
			tofas.menu.position();
			tofas.menu.infobox();
        }
        $(window).resize(function(){
			if (window.matchMedia('(max-width: 991px)').matches) {
				tofas.menu.positionReset();
				tofas.menu.infoboxReset();
			}else{
				tofas.menu.position();
				tofas.menu.infobox();
          }
        });
      },
      sub:function() {
        tofas.menu.itemTag.on('click', function(){
          var subContent = $(this).next();
          if(!subContent.hasClass('sub-opened')) {
            subContent.addClass('sub-opened');
          }
        });
      },
      back: function() {
        tofas.menu.backTag.on('click', function(){
          $(this).parents('ul.sub').removeClass('sub-opened');
        });
      },
      position: function(){
          $('.menu-alpha nav > ul > li').each(function(){
            var calculator = $(this).offset().left;
            $(this).find('ul').css({ "padding-left"  : calculator + 10 + "px" });
          });
      },
      positionReset: function(){
        $('.menu-alpha nav > ul > li').each(function(){
          $(this).find('ul').css({ "padding-left"  : "inherit" });
        });
	  },
	  infobox: function() {
		$('ul.sub li.info').css({ "right" : $('.header-alpha__bottom .container').offset().left + "px" });
	  },
	  infoboxReset: function() {
		$('ul.sub li.info').css({ "right" : "auto" });
	  },
	  mobileMenuClose: function() {
		$('[data-menu-close]').click(function(){
			$('.icon-menu-alpha').removeClass('opened');
			$('.wrapper').removeClass('menu-opened');
			$('.menu-alpha').removeClass('active');
			tofas.buttonSearch.menu("close");
		});
	  }

    }

    /**/

    tofas.menu.init();

  }
});
